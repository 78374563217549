import React from 'react';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { useTonWallet } from '@tonconnect/ui-react';
import { useTonAddress } from '@tonconnect/ui-react';

import './Header.css';

import logo from './Images/logo.svg';
import logomobile from './Images/logo-mobile.svg';
import down from './Images/down.svg';
import walleti from './Images/wallet.svg';
import copy from './Images/copy.svg';
import luna from './Images/luna.svg';
import exit from './Images/exit.svg';

import defaultblue from './Images/default-blue.svg';
import orderblack from './Images/order-black.svg';

const Header: React.FC = () => {
	const [tonConnectUI, setOptions] = useTonConnectUI();
	const wallet = useTonWallet();
	const userFriendlyAddress = useTonAddress();

	const formatAddress = (address: string) => {
		if (!address) return '';
		return `${address.slice(0, 4)}...${address.slice(-4)}`;
	};

	return (
		<div className="header">
			<div className="header-container">
				<div className="container-start">
					<div className="container-logo">
						<img src={logo} alt="" />
					</div>
					<div className="container-menu">
						<div className="container-menu-item active dropdown">
							Трейдинг <img src={down} alt="" />
							<div className="dropdown-content">
								<div className="dropdown-content-item actives">
									<img src={defaultblue} alt="" />
									<div className="dropdown-content-list">
										<div className="title">Простой режим</div>
										<div className="desc">Самый удобный способ трейдинга</div>
									</div>
								</div>
								<div className="dropdown-content-item">
									<img src={orderblack} alt="" />
									<div className="dropdown-content-list">
										<div className="title">Лимитный ордер</div>
										<div className="desc">Запланируйте обмен по более выгодной цене</div>
									</div>
								</div>
							</div>
						</div>
						<div className="container-menu-item">Пуллы</div>
						<div className="container-menu-item">Stake</div>
						<div className="container-menu-item">P2P</div>
						<div className="container-menu-item">Ребалансировка</div>
					</div>
				</div>
				<div className="container-end">
					<div className="container-other">
						<div className="container-other-wallet" onClick={() => wallet ? tonConnectUI.disconnect() : tonConnectUI.openModal()} >
							<img src={walleti} alt="" />
							<span>{wallet ? formatAddress(userFriendlyAddress) + ' ' : 'Подключить кошелёк'}</span>
							{wallet && <img src={copy} alt="" />} {/* Добавлено условное отображение изображения */}
						</div>
						<div className="container-other-wallet-a">
							<img src={walleti} alt="" />
						</div>
						<div className="container-other-select">
							<div className="container-other-select__theme">
								<img src={luna} alt="" />
							</div>
							<div className="container-other-select__exit">
								<img src={exit} alt="" />
							</div>
							<div className="container-other-select__language">
								RU
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="header-a">
				<div className="container-other-wallet-a">
					<img src={walleti} alt="" />
				</div>
			</div>
			<div className="header-container-a">
				<div className="container-start">
					<div className="container-logo">
						<img src={logomobile} alt="" />
						<img src={walleti} alt=""  onClick={() => tonConnectUI.openModal()}/>
					</div>
					<div className="container-menu">
						<div className="container-menu-item active dropdown">
							Трейдинг <img src={down} alt="" />
							<div className="dropdown-content">
								<div className="dropdown-content-item actives">
									<img src={defaultblue} alt="" />
									<div className="dropdown-content-list">
										<div className="title">Простой режим</div>
										<div className="desc">Самый удобный способ трейдинга</div>
									</div>
								</div>
								<div className="dropdown-content-item">
									<img src={orderblack} alt="" />
									<div className="dropdown-content-list">
										<div className="title">Лимитный ордер</div>
										<div className="desc">Запланируйте обмен по более выгодной цене</div>
									</div>
								</div>
							</div>
						</div>
						<div className="container-menu-item">Пуллы</div>
						<div className="container-menu-item">Stake</div>
					</div>
				</div>
				<div className="container-end">
				</div>
			</div>
			<div className="header-a">
				<div className="container-other-wallet-a">
					<img src={walleti} alt="" />
				</div>
			</div>
		</div>
	);
};

export default Header;
