import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import './Pools.css'

import video from './Images/video.svg';
import ton from './Images/ton.svg';
import coin from './Images/coin.svg';

const Pools: React.FC = () => {
    return (
        <div className="wrapper">
					<Header />
					<div className="container-p">
                        <div className="container-up">
						<div className="container-info">
                            <div className="container-info__title">
                                Добыча ликвидности
                            </div>
                            <div className="container-info__desc">Получайте доход за счёт предоставления ликвидности</div>
                        </div>
                        <div className="container-info-buttons">
                            <div  className="container-info-buttons__button">Добавить ликвидность</div>
                            <div  className="container-info-buttons__button-default">
                                <img src={video} alt="" />
                                <div className="text-b">Видео</div>
                            </div>
                        </div>
                        </div>
                        <div className="container-table">
                            <div className="container-table-titles">
                                <div className="container-table-titles__item active-t">Все пулы</div>
                                <div className="container-table-titles__item">Фарминг-пулы</div>
                                <div className="container-table-titles__item">Моя ликвидность</div>
                            </div>
                            <div className="container-table-container">
                                <div className="container-table-container__header">
                                    <div className="container-table-container__header-start">
                                        <div className="container-table-container__header-start__item">TVL</div>
                                        <div className="container-table-container__header-start__item">APR</div>
                                    </div>
                                    <div className="container-table-container__header-end">
                                        <div className="container-table-container__header-end__search">
                                            <input type="text" value={'Найти пул'}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-table-container__item">
                                    <div className="pare">
                                        <div className="pare-img">
                                            <div className="two-img">
                                                <img src={ton} alt="" />
                                                <div className="one-img">
                                                    <img src={coin} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pare-name">BTC/USDT</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="title-t">TVL</div>
                                        <div className="value-t">$95 562 734</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="title-t">Volume 24h</div>
                                        <div className="value-t">$10 510 282</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="title-t">Добыча</div>
                                        <div className="value-t">USDS</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="title-t">24 h APR</div>
                                        <div className="value-t">{'<'}1,1 %</div>
                                    </div>
                                    <div className="button-t">Добавить</div>
                                </div>
                                <div className="container-table-container__item">
                                    <div className="pare">
                                        <div className="pare-img">
                                            <div className="two-img">
                                                <img src={ton} alt="" />
                                                <div className="one-img">
                                                    <img src={coin} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pare-name">BTC/USDT</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="title-t">TVL</div>
                                        <div className="value-t">$95 562 734</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="title-t">Volume 24h</div>
                                        <div className="value-t">$10 510 282</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="title-t">Добыча</div>
                                        <div className="value-t">USDS</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="title-t">24 h APR</div>
                                        <div className="value-t">{'<'}1,1 %</div>
                                    </div>
                                    <div className="button-t">Добавить</div>
                                </div>
                                <div className="container-table-container__item">
                                    <div className="pare">
                                        <div className="pare-img">
                                            <div className="two-img">
                                                <img src={ton} alt="" />
                                                <div className="one-img">
                                                    <img src={coin} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pare-name">BTC/USDT</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="title-t">TVL</div>
                                        <div className="value-t">$95 562 734</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="title-t">Volume 24h</div>
                                        <div className="value-t">$10 510 282</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="title-t">Добыча</div>
                                        <div className="value-t">USDS</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="title-t">24 h APR</div>
                                        <div className="value-t">{'<'}1,1 %</div>
                                    </div>
                                    <div className="button-t">Добавить</div>
                                </div>
                                <div className="container-table-container__item">
                                    <div className="pare">
                                        <div className="pare-img">
                                            <div className="two-img">
                                                <img src={ton} alt="" />
                                                <div className="one-img">
                                                    <img src={coin} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pare-name">BTC/USDT</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="title-t">TVL</div>
                                        <div className="value-t">$95 562 734</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="title-t">Volume 24h</div>
                                        <div className="value-t">$10 510 282</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="title-t">Добыча</div>
                                        <div className="value-t">USDS</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="title-t">24 h APR</div>
                                        <div className="value-t">{'<'}1,1 %</div>
                                    </div>
                                    <div className="button-t">Добавить</div>
                                </div>
                                <div className="container-table-container__item">
                                    <div className="pare">
                                        <div className="pare-img">
                                            <div className="two-img">
                                                <img src={ton} alt="" />
                                                <div className="one-img">
                                                    <img src={coin} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pare-name">BTC/USDT</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="title-t">TVL</div>
                                        <div className="value-t">$95 562 734</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="title-t">Volume 24h</div>
                                        <div className="value-t">$10 510 282</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="dddd title-t">Добыча</div>
                                        <div className="value-t">USDS</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="title-t">24 h APR</div>
                                        <div className="value-t">{'<'}1,1 %</div>
                                    </div>
                                    <div className="button-t">Добавить</div>
                                </div>
                            </div>
                        </div>
                        <div className="container-pages">
                            <div className="container-pages__item">{'<'}</div>
                            <div className="container-pages__item active-i">1</div>
                            <div className="container-pages__item">2</div>
                            <div className="container-pages__item">3</div>
                            <div className="container-pages__item">{'>'}</div>
                        </div>
					</div>
                    <div className="container-p-t">
                        <div className="container-up">
						<div className="container-info">
                            <div className="container-info__title">
                                Добыча ликвидности
                            </div>
                            <div className="container-info__desc">Получайте доход за счёт предоставления ликвидности</div>
                        </div>
                        <div className="container-info-buttons">
                            <div  className="container-info-buttons__button">Добавить ликвидность</div>
                        </div>
                        </div>
                        <div className="container-table">
                            <div className="container-table-titles">
                                <div className="container-table-titles__item active-t">Все пулы</div>
                                <div className="container-table-titles__item">Фарминг-пулы</div>
                                <div className="container-table-titles__item">Моя ликвидность</div>
                            </div>
                            <div className="container-table-container">
                                <div className="container-table-container__header">
                                    <div className="container-table-container__header-start">
                                    {/* <div className="container-table-container__header-end__search">
                                            <input type="text" value={'Найти пул'}/>
                                        </div> */}
                                    </div>
                                    <div className="container-table-container__header-end">
                                        
                                    </div>
                                </div>
                                <div className="container-table-container__item">
                                    <div className="pare">
                                        <div className="pare-img">
                                            <div className="two-img">
                                                <img src={ton} alt="" />
                                                <div className="one-img">
                                                    <img src={coin} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pare-name">BTC/USDT</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="title-t">TVL</div>
                                        <div className="value-t">$95 562 734</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="title-t">24 h APR</div>
                                        <div className="value-t">{'<'}1,1 %</div>
                                    </div>
                                </div>
                                <div className="container-table-container__item">
                                    <div className="pare">
                                        <div className="pare-img">
                                            <div className="two-img">
                                                <img src={ton} alt="" />
                                                <div className="one-img">
                                                    <img src={coin} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pare-name">BTC/USDT</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="title-t">TVL</div>
                                        <div className="value-t">$95 562 734</div>
                                    </div>
                                    <div className="title-table">
                                        <div className="title-t">24 h APR</div>
                                        <div className="value-t">{'<'}1,1 %</div>
                                    </div>
                                </div>
                            </div>
                        </div>
					</div>
					<Footer />
				</div>
    );
};

export default Pools;
