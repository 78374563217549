import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import './Home.css';

import usdt from './Images/usdt.png';
import eth from './Images/eth.png';

import down from './Images/down.svg';
import slippage from './Images/slippage.svg';
import reload from './Images/reload.svg';

import downblue from './Images/down-blue.svg';

const Home: React.FC = () => {
    return (
        <div className="wrapper">
					<Header />
					<div className="container">
						<div className="container-window">
							<div className="container-window__header">
								<div className="container-window__header-start">
									<div className="container-window__header-start__item">Swap</div>
									<div className="container-window__header-start__item gray">Limit</div>
								</div>
								<div className="container-window__header-end">
									<img src={reload} alt="" />
								</div>
							</div>
							<div className="container-window__main">
								<div className="forcircle">
								<div className="container-window__main-container">
									<div className="container-window__main-container__header">
										<div className="text">Вы продаёте</div>
										<div className="text">Баланс: 0.0293</div>
									</div>
									<div className="container-window__main-container__main">
										<div className="value">
											<img src={usdt} alt="" />
											<div className="text-c">USDT</div>
											<img src={down} alt="" className='downselect'/>
										</div>
										<div className="value">
											<div className="text-c">5 000</div>
										</div>
									</div>
									<div className="container-window__main-container__footer">
									<div className="text">Tether USDT</div>
									<div className="text">~ $4 916.12</div>
									</div>
								</div>
								<div className="container-window__circle">
									<img src={downblue} alt="" />
								</div>
								<div className="container-window__main-container border">
									<div className="container-window__main-container__header">
										<div className="text">Вы получаете</div>
										<div className="text">Баланс: 0</div>
									</div>
									<div className="container-window__main-container__main">
										<div className="value">
											<img src={eth} alt="" />
											<div className="text-c">ETH</div>
											<img src={down} alt="" />
										</div>
										<div className="value">
											<div className="text-c">1.88213</div>
										</div>
									</div>
									<div className="container-window__main-container__footer">
									<div className="text">Ether</div>
									<div className="text">~ $4 988.08 (- 0,16%)</div>
									</div>
								</div>
								</div>
								<div className="border slippage">
									<div className="container-slippage-container__start">
										<img src={slippage} alt="" />
										<div className="text">
											Проскальзование
										</div>
									</div>
									<div className="container-slippage-container__end">
										<div className="text-button">Auto</div>
										<img src={down} alt="" />
										</div>
								</div>
							</div>
							<div className="container-window__footer">
								<div className="container-window__footer-button">
									<div className="button">
										Обменять
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
    );
};

export default Home;
