import React from 'react';

import './Footer.css';

import logo from './Images/logo.svg';

import down from './Images/down.svg';
import themelight from './Images/theme-light.svg';
import asklight from './Images/ask-light.svg';

const Footer: React.FC = () => {
    return (
        <div className="footer">
            <div className="footer-container">
            <div className="container-logotype">
                <img src={logo} alt="" />
            </div>
            <div className="container-links">
                <div className="container-links__title">
                    Tools
                </div>
                <div className="container-links__links">
                    <div className="container-links__links-item">Trade/Swap</div>
                    <div className="container-links__links-item">Pools</div>
                    <div className="container-links__links-item">Stake</div>
                </div>
            </div>
            <div className="container-links">
                <div className="container-links__title">
                    Rebalancer
                </div>
                <div className="container-links__links">
                    <div className="container-links__links-item">About</div>
                    <div className="container-links__links-item">Blog</div>
                    <div className="container-links__links-item">Whitepaper</div>
                    <div className="container-links__links-item">Privacy policy</div>
                </div>
            </div>
            <div className="container-links">
                <div className="container-links__title">
                    Developers
                </div>
                <div className="container-links__links">
                    <div className="container-links__links-item">Documentation</div>
                    <div className="container-links__links-item">Github</div>
                </div>
            </div>
            <div className="container-links">
                <div className="container-links__title">
                    Join our community
                </div>
                <div className="container-links__links-com">
                    <div className="container-links__links-item-com">RU</div>
                    <div className="container-links__links-item-com">EN</div>
                    <div className="container-links__links-item-com">X</div>
                </div>
            </div>
            </div>
            <div className="footer-container-a">
            <div className="container-links-a">
            <div className="container-links">
                <div className="container-links__title">
                    Tools
                </div>
                <div className="container-links__links">
                    <img src={down} alt="" />
                </div>
            </div>
            <div className="container-links">
                <div className="container-links__title">
                    Rebalancer
                </div>
                <div className="container-links__links">
                    <img src={down} alt="" />
                </div>
            </div>
            <div className="container-links">
                <div className="container-links__title">
                    Developers
                </div>
                <div className="container-links__links">
                    <img src={down} alt="" />
                </div>
            </div>
            </div>
            <div className="container-other-a">
                <div className="container-other-a__item">
                    <img src={themelight} alt="" />
                </div>
                <div className="container-other-a__item">
                    RU
                </div>
                <div className="container-other-a__item">
                    <img src={asklight} alt="" />
                </div>
            </div>
            </div>
        </div>
    );
};

export default Footer;
